import { Button } from '@components/ui/button';
import Link from 'next/link';
import { useSession } from '@hooks/index';

interface GetYourPortraitsButtonProps {
  variant?: 'default' | 'accent' | 'secondary' | 'ghost';
  size?: 'default' | 'sm' | 'lg' | 'xl' | 'xs' | 'icon';
  className?: string;
  source: string;
  redirect?: string;
  loggedOutText?: string;
  loggedInText?: string;
  fullWidth?: boolean;
}

export function GetYourPortraitsButton({
  variant = 'accent',
  size = 'xl',
  className = '',
  source,
  redirect = '/pricing',
  loggedInText = 'Go to dashboard',
  loggedOutText = 'Get started for free',
  fullWidth = true
}: GetYourPortraitsButtonProps) {
  const { isLoggedIn } = useSession();

  if (isLoggedIn) {
    return (
      <Button
        className={`${fullWidth ? 'w-full md:w-fit' : ''} ${className}`}
        variant={variant}
        size={size}
        asChild
        data-element-text={loggedInText}
      >
        <Link href="/dashboard">{loggedInText}</Link>
      </Button>
    );
  }

  return (
    <Button
      className={`${fullWidth ? 'w-full' : ''} ${className}`}
      variant={variant}
      size={size}
      asChild
      data-element-location="hero"
      data-cta-type="primary"
      data-element-text={loggedOutText}
      data-component-parent={source}
      id="hero-primary-cta"
    >
      <Link
        href={{
          pathname: '/onboarding/start',
          query: { redirect, source }
        }}
      >
        {loggedOutText}
      </Link>
    </Button>
  );
}
