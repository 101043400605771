'use client';

import { Check, X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { GetYourPortraitsButton } from '@components/Button/get-your-portraits';
import Image from 'next/image';

interface ComparisonRowProps {
  title: string;
  description: string;
  picStudio: 'Yes' | 'No';
  photographer: 'Yes' | 'No';
}

const Icon = ({ isYes }: { isYes: boolean }) => (
  <div
    className={cn(
      'h-6 w-6 aspect-square rounded-full flex items-center justify-center',
      isYes ? 'bg-accent text-white' : 'bg-rose-500 text-white'
    )}
  >
    {isYes ? <Check size={14} strokeWidth={4} /> : <X size={16} strokeWidth={4} />}
  </div>
);

interface ComparisonPhotoProps {
  realPhotoUrl: string;
  aiPhotoUrl: string;
  className?: string;
}

const ComparisonPhotos = ({ realPhotoUrl, aiPhotoUrl, className }: ComparisonPhotoProps) => (
  <div className={cn('grid grid-cols-2 gap-2', className)}>
    <div className="space-y-1">
      <div className="relative aspect-[3/4] rounded-lg overflow-hidden">
        <Image src={realPhotoUrl} alt="Real photo example" fill className="object-cover" />
      </div>
      <p className="text-sm text-center text-muted-foreground">Real Photo</p>
    </div>
    <div className="space-y-1">
      <div className="relative aspect-[3/4] rounded-lg overflow-hidden">
        <Image src={aiPhotoUrl} alt="AI headshot example" fill className="object-cover" />
      </div>
      <p className="text-sm text-center text-muted-foreground">AI Headshot</p>
    </div>
  </div>
);

export default function ComparisonTable() {
  const features: ComparisonRowProps[] = [
    {
      title: 'Professional Quality',
      description: 'High resolution images with professional lighting and composition.',
      picStudio: 'Yes',
      photographer: 'Yes'
    },
    {
      title: 'Affordable Pricing',
      description: 'Get professional portraits at a fraction of the cost.',
      picStudio: 'Yes',
      photographer: 'No'
    },
    {
      title: 'Delivered in Minutes',
      description: 'No waiting for days; portraits are ready in minutes.',
      picStudio: 'Yes',
      photographer: 'No'
    },
    {
      title: 'Customizable & Style Options',
      description: 'AI captures your unique style effortlessly.',
      picStudio: 'Yes',
      photographer: 'No'
    },
    {
      title: 'Convenient & Remote',
      description: 'Upload photos anytime, anywhere—no scheduling needed.',
      picStudio: 'Yes',
      photographer: 'No'
    },
    {
      title: 'Do-Overs at Low Cost ($14.99)',
      description: 'Revise your model without expensive re-shoots.',
      picStudio: 'Yes',
      photographer: 'No'
    }
  ];

  const comparisonPhotos = [
    {
      realPhotoUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/143e144b-05f2-402e-724b-d6d0929d7400/public',
      aiPhotoUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/927973e3-fa31-4cec-04b1-ac6cff4f2300/public'
    },
    {
      realPhotoUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/ef1b78f9-d48d-4203-3dbd-c42e32981c00/public',
      aiPhotoUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/54939193-3f03-4b91-248d-a67efdb0ad00/public'
    }
  ];

  return (
    <div className="w-full mx-auto py-10 px-4">
      <h2 className="text-3xl md:text-4xl font-bold tracking-tight text-balance mb-8">
        Why Choose AI Over Photoshoots?
      </h2>

      <div className="flex flex-col md:flex-row gap-6 md:gap-20">
        <div className="flex-1">
          <div className="hidden md:block relative">
            <div className="absolute top-0 bottom-0 right-[20%] w-[20%] bg-accent/0 z-0" />
            <Table className="max-w-6xl ">
              <TableHeader>
                <TableRow className="hover:bg-transparent bg-red-500/0">
                  <TableHead className="w-[50%] md:w-[60%]"></TableHead>
                  <TableHead className="text-center w-[25%] md:w-[20%]">PicStudio.Ai</TableHead>
                  <TableHead className="text-center w-[25%] md:w-[20%]">Photographer</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="">
                {features.map((feature, index) => (
                  <TableRow key={index} className="odd:bg-foreground/10">
                    <TableCell className="align-top rounded-l-lg overflow-hidden">
                      <h3 className="font-bold leading-none text-base mb-1">{feature.title}</h3>
                      <p className="text-sm text-muted-foreground mt-1">{feature.description}</p>
                    </TableCell>
                    <TableCell className="text-center p-4">
                      <div className="flex justify-center items-center">
                        <Icon isYes={feature.picStudio === 'Yes'} />
                      </div>
                    </TableCell>
                    <TableCell className="text-center p-4">
                      <div className="flex justify-center items-center">
                        <Icon isYes={feature.photographer === 'Yes'} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="md:hidden space-y-8">
            {features.map((feature, index) => (
              <div key={index} className="border-b border-border last:border-0">
                <div className="px-4 pb-4 w-full">
                  <h3 className="font-bold text-base mb-1 text-center">{feature.title}</h3>
                  <p className="text-sm text-muted-foreground text-center">{feature.description}</p>
                </div>

                <div className="grid grid-cols-2 gap-4 px-4 py-2 bg-muted/5 pb-6">
                  <div className="flex flex-col items-center gap-3">
                    <Icon isYes={feature.picStudio === 'Yes'} />
                    <span className="text-xs text-center">PicStudio</span>
                  </div>
                  <div className="flex flex-col items-center gap-3">
                    <Icon isYes={feature.photographer === 'Yes'} />
                    <span className="text-xs text-center">Photographer</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full flex py-4">
            <GetYourPortraitsButton source="comparison_table" className="w-fit mx-auto" size="xl" />
          </div>
        </div>

        <div className="md:w-[400px] space-y-4">
          {comparisonPhotos.map((photos, index) => (
            <ComparisonPhotos
              key={index}
              realPhotoUrl={photos.realPhotoUrl}
              aiPhotoUrl={photos.aiPhotoUrl}
              className="w-full"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
