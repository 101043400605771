import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

type PicStudioFaqItem = {
  question: string;
  answerRichText?: any;
};

const FAQ = () => {
  const [faqs, setFaqs] = useState<PicStudioFaqItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFAQs = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch('/api/faq');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const faqItems = data.entries.flatMap((entry: any) =>
          entry.fields.picStudioFaqItem.map((item: any) => ({
            question: item.fields.question,
            answerRichText: item.fields.answerRichText || null
          }))
        );
        setFaqs(faqItems);
      } catch (error) {
        setError('Failed to load FAQs.');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFAQs();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col md:flex-row gap-6 lg:gap-20 py-10">
      <div className="w-full md:w-1/3 space-y-4">
        <div className="text-accent">Frequently asked questions</div>
        <h2 className="text-2xl lg:text-4xl text-foreground font-bold">Everything you need to know</h2>
      </div>
      <div className="w-full">
        <dl className="space-y-6 divide-y divide-white/10">
          {faqs.map((faq, i) => (
            <Disclosure as="div" key={i} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left">
                      <span className="text-base font-semibold">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <div className="text-base leading-7">
                      {faq.answerRichText ? documentToReactComponents(faq.answerRichText) : <p>No answer provided.</p>}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default FAQ;
