import useOneTapSignin from '@hooks/useOneTapSignin';

const OneTapComponent = () => {
  useOneTapSignin({
    redirect: true,
    parentContainerId: 'oneTap',
    callbackUrl: `/login/success?redirect=${encodeURIComponent('/dashboard?authSuccess=true')}`
  });

  return <div id="oneTap" className="fixed top-2 right-2 z-[99999]" />;
};

export default OneTapComponent;
