import React from 'react';

interface Logo {
  alt: string;
  src: string;
}

interface LogoCloudProps {
  className?: string;
  logos?: Logo[];
}

export const logos = [
  {
    alt: 'Disney',
    src: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4698b545-58c7-4460-9e1f-a2e3a756c400/public'
  },
  {
    alt: 'LinkedIn',
    src: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/1807e9c5-4fb0-4d11-5e7c-1ed71f78ce00/public'
  },
  {
    alt: 'Meta',
    src: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/861c7d02-f4ae-4f20-02d1-c9e31ef16200/public'
  },
  {
    alt: 'Google',
    src: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/fe82553f-aedf-471b-ac9a-6e07f81d1c00/public'
  },
  {
    alt: 'Microsoft',
    src: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4da1d5b9-1d3e-4d35-ac23-4777ef58b600/public'
  },
  {
    alt: 'Amazon',
    src: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c20a60f1-7bdd-426f-7bbb-4c1f45baf000/public'
  }
];

export function LogoCloud({ className = '', logos: customLogos }: LogoCloudProps) {
  const logosToShow = customLogos || logos;

  return (
    <div className={`mx-auto max-w-screen-2xl px-6 lg:px-8 ${className}`}>
      <div className="grid grid-cols-3 lg:grid-cols-6 gap-4 opacity-40">
        {logosToShow.map((logo, index) => (
          <img
            key={index}
            alt={logo.alt}
            src={logo.src}
            width={158}
            height={48}
            className="max-h-12 w-full object-contain lg:col-span-1"
          />
        ))}
      </div>
    </div>
  );
}
